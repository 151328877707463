import React from 'react'
import Domicile from './Domicile'
function ListSPG({domiciles}) {
    
    return (
        <div className='mt-20 flex bg-slate-500'>
            <div className='w-1/5'>
                <Domicile domiciles={domiciles}/>
            </div>
            <div className='w-2/5'>
                
            </div>
        </div>
    )
}

export default ListSPG