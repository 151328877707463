"use strict";
import React, { useRef, useState, useEffect } from 'react'
import waislogo from '../../assets/wais-logo.png'
import backgroundDesktop from '../../assets/desktop-background.png'
import background from '../../assets/background2.png'
import barcode from '../../assets/barcode.png'
import NavigationMenu from '../../components/NavigationMenu';
import { FullPage, Slide } from 'react-full-page';
import ListSPG from '../../components/ListSPG';
import { API } from '../../utils/api';
import { Api } from '../../utils/apiServer';
import Sidebar from '../../components/Sidebar';
import DefaultImage from '../../assets/default-image.jpg';
import mergeImages from 'merge-images';
import watermarklogo from '../../assets/wais_watermark.png'
import Pagination from '../../components/Pagination';

const imageUrlToBase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
        reader.onerror = reject;
    });
};



const SPG = () => {
    const [filter, setFilter] = useState([])
    const [selectedImage, setSelectedImage] = useState({
        images: []
    })
    const [spg, setSPG] = useState([])
    const [page, setPage] = useState(1)
    const [lastPage, setLatPage] = useState(10)
    const [rows, setRows] = useState(10)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // document.getElementById('main-body').style.backgroundImage = `url('${background}')`
        // document.getElementById('main-body').style.backgroundSize = `cover`
        // document.getElementById('main-body').style.height = `100%`
        init();
    }, [])

    const init = async () => {
        const params = {
            access: 'none',
            method: API.OUTSOURCE.type,
            url: API.OUTSOURCE.url,
            reqBody: {
                page: page,
                rows: rows,
                where: filter,
                order: [
                    {
                        field: 'outsource_id',
                        sort: 'asc'
                    }
                ]
            }
        }
        setLoading(true)
        const response = await Api(params)
        console.log('data', response.data.data)
        let OriginImg
        for (const i in response.data.data) {
            if (response.data.data[i].images.length === 0) {
                response.data.data[i].images[0] = await mergeImages([DefaultImage, watermarklogo])
            } else {
                // console.log(response.data.data[i].images[0].image)
                // console.log(await imageUrlToBase64(response.data.data[i].images[0].image))
                OriginImg = await imageUrlToBase64(response.data.data[i].images[0].image)
                response.data.data[i].images[0] = await mergeImages([OriginImg, watermarklogo])
            }
        }
        setSPG(response.data.data)
        setPage(response.data.page)
        setLatPage(response.data.lastPage)
        setLoading(false)

        // await imageUrlToBase64('http://localhost:4100/default-image.jpg').then((x)=>console.log(x))
        // await imageUrlToBase64('https://cdn.mediacartz.com/files/wais/XTR33xyy91.jpg').then((x)=>console.log(x))
    }



    useEffect(() => {
        // console.log(filter)
        init();
    }, [filter, page])

    const handleClick = (s) => {
        // console.log(s)
        setSelectedImage(s)
        document.getElementById('my_modal_1').showModal()
    }

    return (
        <div className=''>
            <NavigationMenu />
            <div className='flex    mt-32 min-w-[1024px] overflow-auto'>
                <div className='w-2/6 overflow-scroll !overflow-hidden'>
                    <Sidebar onChecked={(r) => {
                        setFilter(r)
                    }} />
                </div>

                <div className='4/6 pl-10 pr-10'>
                    <div className='title text-4xl font-extrabold'>List of Our SPG</div>
                    <div>We provide all ecosystem for workspace with several requirement and needs. On field team services will help your bushiness to penetrate the market</div>
                    <div className='grid grid-cols-5 gap-2 pt-5'>
                        {loading ? <div>Loading ...</div> :
                            <>
                                {spg.map((s, i) => (
                                    <div key={i}
                                        className='bg-amber-200 rounded-md pb-2 w-[300] overflow-hidden cursor-pointer'
                                        onClick={() => handleClick(s)}
                                    >
                                        <img src={s.images[0]} className="w-[300]" alt="" />
                                        <div className='text-center pt-2'>
                                            <div className='font-black'>{s.name}</div>
                                            <div>{s.domicile}</div>
                                            <div className='flex items-center'>

                                                <div className='flex flex-col justify-center items-center w-1/2'>
                                                    <div className='text-3xl text-center font-extrabold antialiased'>{s.age}</div>
                                                    <div className='text-xs'>tahun</div>
                                                </div>
                                                <div className='w-1/2'>
                                                    <div className='text-xs'>{s.height} cm</div>
                                                    <div className='text-xs'>{s.weight} kg</div>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                ))}
                            </>
                        }

                    </div>
                    <Pagination page={page} lastPage={lastPage}
                        onClick={(v) => {
                            setPage(v)
                        }} />
                </div>
            </div>

            <dialog id="my_modal_1" className="modal">
                <div className="modal-box bg-white max-w-5xl">
                    <div className='flex'>
                        <div className="carousel">
                            <div id="slide1" className="carousel-item relative w-full">
                                <img src={selectedImage?.images[0]} alt="" />
                            </div>
                        </div>
                        <div className='ml-5 mr-5'>
                            <div className='grid grid-cols-2'>
                                <div className=''>Nama</div>
                                <div>{selectedImage.name}</div>
                            </div>
                            <div className='grid grid-cols-2'>
                                <div>Domisili</div>
                                <div>{selectedImage.domicile}</div>
                            </div>
                            <div className='grid grid-cols-2'>
                                <div>Umur</div>
                                <div>{selectedImage.age} tahun</div>
                            </div>
                            <div className='grid grid-cols-2'>
                                <div>Tinggi</div>
                                <div>{selectedImage.height} cm</div>
                            </div>
                            <div className='grid grid-cols-2'>
                                <div>Berat</div>
                                <div>{selectedImage.weight} kg</div>
                            </div>
                            <div className='grid grid-cols-2'>
                                <div>Pengalaman</div>
                                <div>
                                    {selectedImage.experience}
                                </div>
                            </div>


                        </div>
                    </div>




                    <div className="modal-action">
                        <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <button className="btn">Close</button>
                        </form>
                    </div>
                </div>
            </dialog>

        </div>
    )
}

export default SPG