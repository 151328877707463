import React, { useEffect, useState } from 'react'
import { navs } from '../../jsons/navs'
import Logo from '../../assets/papperless-logo.png'
import { Menu } from 'react-daisyui'
import { API } from '../../utils/api';
import { Api } from '../../utils/apiServer';
import { FiUsers } from "react-icons/fi"


function Sidebar({onChecked}) {
    const [locations, setLocations] = useState([]);
    const [checked, setChecked] = useState([]);


    // console.log(checked)
    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        const params = {
            access: 'none',
            method: API.DOMICILE.type,
            url: API.DOMICILE.url,
            reqBody: {
                order: [
                    {
                        field: 'domicile',
                        sort: 'asc'
                    }
                ]
            }
        }

        const response = await Api(params)
        if (response.status) {
            let datas = []
            let nomor = 0;
            for (const iterator of response.data) {
                datas.push({
                    id: `lok_${nomor}`,
                    domicile: iterator.domicile
                })
                nomor++;
            }
            setLocations(datas)
        }
        // console.log(response.data)
    }

    const Domicile = () => {
        return (
            <div>
                <div>Lokasi</div>
            </div>
        )
    }

    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
        onChecked(updatedList)
    };

    return (
        <div className="rounded-none bg-amber-100 pb-10 pr-3 rounded-r-3xl">
            {/* <input type="checkbox" /> */}
            <div className="collapse-title text-xl font-medium">
                Lokasi
            </div>
            <div className="h-80 overflow-scroll !overflow-x-hidden">
                {locations.map((location, i) => (
                    <label className="label cursor-pointer justify-normal" key={i}>
                        <input
                            type="checkbox"
                            className='checkbox'
                            name={location.id}
                            id={location.id}
                            value={location.domicile}
                            onChange={handleCheck}
                        />
                        <span className="label-text pl-3">{location.domicile}</span>
                    </label>
                ))}

            </div>
        </div>
    )
}

export default Sidebar