import React, { useState, useEffect } from 'react'
import { Outlet, redirect, Navigate } from 'react-router-dom'
// import Navigation from '../components/Navigation'

import Footer from '../components/Footer'
import { session, clearSession, setSession } from '../utils/AuthSession'

function MainLayout() {
    return (
        // <Navigation>
            <Outlet />
        // </Navigation>
    )

}

export default MainLayout