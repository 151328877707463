import React, {useState} from 'react'

function Pagination({page, lastPage, onClick}) {

    return (
        <div className='mt-5 mb-5'>
            <div className='flex justify-between'>
                <button className='btn bg-amber-500 text-white border-none hover:bg-amber-200' onClick={()=>{
                    if(page === 1){
                        onClick(1)
                    }else{
                        onClick(page-1)
                    }
                }}>Prev Page</button>
                <div>{page}</div>
                <button className='btn bg-amber-500 text-white border-none hover:bg-amber-200' onClick={ () =>{
                    if(page === lastPage){
                        onClick(lastPage)
                    }else{
                        onClick(page+1)
                    }
                }}>Next Page</button>

            </div>
        </div>
    )
}

export default Pagination