"use strict";
import React, { useRef, useState, useEffect } from 'react'
import waislogo from '../../assets/wais-logo.png'
import backgroundDesktop from '../../assets/desktop-background.png'
import background from '../../assets/background2.png'
import barcode from '../../assets/barcode.png'
import Navigation from '../../components/Navigation';
import { FullPage, Slide } from 'react-full-page';
import ListSPG from '../../components/ListSPG';
import {API} from '../../utils/api';
import {Api} from '../../utils/apiServer'; 

const Home = () => {
    const [domiciles,setDomiciles] = useState([])
    const [menuSelect, setMenuSelect] = useState(0)
    const refSlide = useRef()

    // console.log(refSlide)
    
    useEffect(()=>{
        init(); 
    },[])

    const init = async () =>{
        const params = {
            access: 'none',
            method: API.DOMICILE.type,
            url: API.DOMICILE.url,
            reqBody: {
                order: [
                    {
                        field: "domicile",
                        sort: 'asc'
                    }
                ]
            }

        }
        const response = await Api(params);
        if (response) {
            setDomiciles(response.data)
            

        }
    }

    return (
        <div >
            <Navigation selected={menuSelect} active={refSlide}/>
            <FullPage
                ref={refSlide}
                afterChange={(x) => {
                    setMenuSelect(x.to)
                    // console.log(x)
                    switch (x.to) {
                        case 0:
                            break;
                        case 1:
                            break;
                        case 2:
                            break;
                        case 3:
                            break;
                        default:
                            break;
                    }
                }}
            >
                <Slide>
                    <div className='flex  h-full '>
                        <div className='w-1/2  flex items-center justify-center'>
                            <div>
                                <img src={waislogo} alt="" />
                                <div className=''>
                                    <div className='text-4xl text-center font-bold bg-amber-400 p-3 rounded-3xl text-white'>Pick Your Brand Associate, Now</div>
                                </div>

                            </div>
                        </div>
                        <div className='w-1/2 flex  items-center bg-amber-400'>
                            <div className='pl-[30%] pr-16'>
                                <div className='text-4xl font-bold text-white pb-3'>Providing best quality of workspace to support enhance and your bushiness  </div>
                                <div>We provide al ecosystem for  workforce with several requirement and needs. On field team services will help your bushiness to penetrate the market.</div>
                            </div>

                        </div>
                    </div>
                </Slide>
                
                <Slide>
                    <div className='flex h-full ' style={{ backgroundImage: `url(${background})` }}>
                        <div className='w-1/2 flex items-center justify-center'>
                            <div className='pl-[20%]'>
                                <div className='text-5xl font-black text-amber-400'>
                                    <div>We Need</div>
                                    <div>Your talent</div>

                                </div>
                                <div className='pt-5'>
                                    Kami lagi nyari temen yang asik dan enerjik buat gabung di tim penjualan untuk membantu client besar kami, kalau kami puunya passion yang tinggi, gaul, suka tantangan dan doyan hasil.</div>
                                <div className=''>
                                    Yuk kita bangun jadi tea yang solid !
                                </div>
                                <div className='pt-3'>Untuk info lebih lanjut, Hubungi:</div>
                                <div className='text-2xl font-bold'>+62-8577-4089-677</div>
                                <div className='text-2xl font-bold'>+63-822-6853-1196</div>
                            </div>

                        </div>
                        <div className='w-1/2 flex items-center justify-center bg-amber-400'>
                            <div className='text-center'>
                                <img src={barcode} alt="" />
                                <div className=' btn bg-amber-400 text-center border-none text-white'>
                                    <div>Kirim profile anda</div>
                                    
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </Slide>
                <Slide>
                    <div className='flex h-full ' style={{ backgroundImage: `url(${background})` }}>
                        <div className='w-1/2 flex items-center justify-center'>
                            <div className='pl-[20%]'>
                                <div className='text-5xl font-black text-amber-400'>
                                    <div>Get in Touch</div>
                                    <div>With Us</div>

                                </div>
                                <div className='pt-5'>
                                    We provide all ecosystem for workspace with  several requirement and needs.On field team service will help your bushiness to penetrate the market
                                </div>
                            </div>

                        </div>
                        <div className='w-1/2 flex flex-col items-center justify-center pl-[5%] pr-[5%] bg-amber-400'>
                            <div>
                                <input type="text" placeholder="Full name" className="input input-bordered w-full mb-2 bg-amber-100" />
                                <input type="text" placeholder="Company name" className="input input-bordered w-full mb-2 bg-amber-100" />
                                <input type="text" placeholder="Email" className="input input-bordered w-full mb-2 bg-amber-100" />
                                <button className='btn bg-amber-400 border-none text-white hover:bg-amber-500'>Send</button>

                            </div>
                            
                        </div>
                    </div>
                </Slide>
                
            </FullPage>
        </div>
    )
}

export default Home