import React from 'react'
import {
    BrowserRouter as Router, Routes, Route, Outlet
} from "react-router-dom";

import Home from "../../pages/Home";
import ErrorPage from "../../pages/ErrorPage";
import MainLayout from '../../layout/MainLayout';
import SPG from '../../pages/SPG';

const RootRouter = () => {
    return (
        <>
            <Router>
                <Routes>

                    <Route path='/' element={<MainLayout />}>
                        <Route index element={<Home/>} />
                        <Route path='listspg'  element={<SPG/>} />
                    </Route>

                    
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
                
            </Router>

            
        </>

    )

}


export default RootRouter