
import { useState, useEffect } from "react";
import logo from '../../assets/wais-logo.png'
import { useNavigate } from 'react-router-dom';

const NavigationMenu = ({ children }) => {
    let navigate = useNavigate();
    return (
        <div>
            <div className="navbar fixed top-0 bg-white">
                <div className="navbar-start">
                    <div className="dropdown">
                        <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                        </div>
                        <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-orange-500 rounded-box w-52">
                            <li><a>About Us</a></li>
                            <li><a>Join Us</a></li>
                            <li><a>Contact Us</a></li>
                            <li><a>List of SPG</a></li>
                        </ul>
                    </div>
                    <a className="">
                        <img src={logo} className="" alt="" width={120} />
                    </a>
                </div>
                <div className="navbar-end hidden lg:flex">
                    <ul className="menu menu-horizontal px-0 text-md font-extrabold border-b-red-500 border-b-2">
                        <li>

                            <a
                                className={`text-black`}
                                onClick={() => {
                                    navigate('/')
                                }}
                            >
                                About Us
                            </a>
                        </li>

                        <li>
                            <a className={`"text-black"}`}
                                onClick={() => {
                                    navigate('/')
                                }}
                            >
                                Join Us
                            </a>
                        </li>
                        <li>
                            <a className="text-black"
                                onClick={() => {
                                    navigate('/')
                                }}
                            >
                                Contact Us
                            </a>
                        </li>
                        <li>
                            <a
                                className="text-amber-500"
                                onClick={() => {

                                }}
                            >
                                List of SPG
                            </a>
                        </li>
                    </ul>
                </div>


            </div>
            
            {children}
        </div>

    )
}

export default NavigationMenu
