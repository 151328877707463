export const API = {

	DOMICILE: {
		url: 'public/domicile',
		type: 'get'
	},

	OUTSOURCE: {
		url: '/public/outsources',
		type: 'get'
	}
}